// @flow
import * as React from 'react';
import { DEVICE_PIXEL_RATIO } from 'ol/has';
import { Fill, Icon, Stroke, Style, Circle } from 'ol/style';

import type FeatureType from 'ol/Feature';

import huc8 from '../../data/huc8_mo.pbf';
import drainage from '../../data/TN_Nitrate_Trend_Catchments.pbf';
import trend_stations from '../../data/trend_stations.pbf';

// MO Water Portal
import mo_sites from '../../data/missouri_stations_v5.pbf';
import mo_sitedata from '../../data/missouri_alldata_v5_gapfill.json';
import mo_trenddata from '../../data/usgs_trend_output.json';

import patternNoData from '../../images/pattern_no_data.png';
import annualYieldData from '../../data/annual_yield.json';

import markerMonitoringSite from '../../images/marker_monitoring_site.png';
import markerMonitoringSiteInc from '../../images/marker_monitoring_site_up.png';
import markerMonitoringSiteDec from '../../images/marker_monitoring_site_down.png';

import ol from 'openlayers';
export const initialState = {
    boundary: 'huc8',
    nutrient: 'Nitrogen',
    year: 2022
};

export const GEOSERVER_URL = process.env.GEOSERVER_URL || '';

// A missing `boundaries` prop from a legend item means it will be shown for all boundary types
export const CONTEXTUAL_LAYERS: Array<{ title: string; id: string; zIndex?: number, boundaries?: Array<string> }> = [
    //{ title: 'Rivers', id: 'gltg:us-rivers', zIndex: 2 },
    //{ title: 'State Boundaries', id: 'gltg:us-states' },
    //{ title: 'HUC8 Trends', id: 'gltg_huc8-trends' },
];

export const getOverallFeatureLabels = (boundary: string) => {
    // Returns an array of two items: the first item is the active boundary label,
    // and the second item is its variable name in `data.json`, which can be used for rendering labels too.
    switch (boundary) {
        case 'trends':
            return ['Missouri', 'USGS Long-Term Trends'];
        case 'huc8':
            return ['Missouri', 'Monitoring Stations'];
        case 'watershed':
            return ['Mississippi River Basin', 'Nutrient Load to Gulf of Mexico'];
        default:
            return [null, null];
    }
};

export const MAP_BOUNDS = [
    -10759887.5976,
    4260628.4218,
    -9749981.2129,
    4984639.9537
];

export const getLayerExtent = (boundary: string) => {
    return MAP_BOUNDS;

    switch (boundary) {
        case 'drainage':
            return [-10673131.179092214, 4240945.513367433, -9272804.820907786, 5703644.486632567];
        case 'huc8':
            return [-10973131.179092214, 4240945.513367433, -9302804.820907786, 5703644.486632567];
        case 'watershed':
            return [-10923839.372435283, 4545502.562858378, -9523076.314751584, 6008657.686866852];
        default:
            return MAP_BOUNDS;
    }
};

export const FEATURE_STYLE_INFO = [
    {
        label: 'No data',
        image: patternNoData
    },
    {
        label: '<5',
        color: '#EAEDF2'
    },
    {
        label: '5-9.99',
        color: '#C7D6E6'
    },
    {
        label: '10-14.99',
        color: '#93BDD4'
    },
    {
        label: '15-19.99',
        color: '#4D94C1'
    },
    {
        label: '20-24.99',
        color: '#1B64A7'
    },
    {
        label: '>25 lb/acre',
        color: '#062D64'
    }
];

export const getNutrientValueCategoryIndex = (nutrientLevel?: number): number => {
    if ((nutrientLevel !== 0 && !nutrientLevel) || nutrientLevel < 0) {
        return 0;
    }
    if (nutrientLevel < 5) {
        return 1;
    }
    if (nutrientLevel < 10) {
        return 2;
    }
    if (nutrientLevel < 15) {
        return 3;
    }
    if (nutrientLevel < 20) {
        return 4;
    }
    if (nutrientLevel < 25) {
        return 5;
    }
    return 6;
};

export const HUC8_STYLE_INFO = [
    {
        label: 'NA',
        image: patternNoData
    },
    {
        label: '',
        color: '#39A800'
    },
    {
        label: 'Decreasing',
        color: '#39A800'
    },
    {
        label: '',
        color: '#39A800'
    },
    {
        label: 'Not Changing',
        color: '#FFFFBF'
    },
    {
        label: 'Increasing',
        color: '#FF5502'
    }
];

export const HUC8_STYLE_INFO_LEGEND = [
    {
        label: 'NA',
        image: patternNoData
    },
    {
        label: 'Decreasing',
        color: '#39A800'
    },
    {
        label: 'Not Changing',
        color: '#FFFFBF'
    },
    {
        label: 'Increasing',
        color: '#FF5502'
    }
];

export const TRENDS_STYLE_INFO = [
    {
        label: 'Decreasing',
        color: '#39a800'
    },
    {
        label: 'Not Changing',
        color: '#ffffbf'
    },
    {
        label: 'Increasing',
        color: '#FF5502'
    }
];

export const getSiteIcon = (station_id?: string, nutrient?: string): string => {
    if (mo_sitedata[station_id] && mo_sitedata[station_id][nutrient]) {
        const records = mo_sitedata[station_id][nutrient];

        const years = [];
        records.forEach(entry => {
            const yyyy = entry.x.split('-')[0];
            if (!years.includes(yyyy)) years.push(yyyy);
        });

        // Use that to determine size of the circles in stages
        let rad = 0;
        let fill = 'white';

        if (years.length > 5 && years.length <= 10) {
            rad = 6;
            fill = '#4D94C1';
        }
        if (years.length > 10 && years.length <= 20) {
            rad = 8;
            fill = '#1B64A7';
        }
        if (years.length > 20) {
            rad = 10;
            fill = '#062D64';
        }

        if (years.length >= 5) {
            return new Circle({
                radius: rad,
                fill: new Fill({ color: fill }),
                stroke: new Stroke({
                    color: 'black',
                    width: 1
                })
            });
        }
    }
};

export const getTrendIcon = (station_id?: string, nutrient?: string): string => {
    let record = {}
    if (mo_trenddata[station_id]) {
        if (nutrient == "Nitrogen" && mo_trenddata[station_id]["Dissolved nitrate plus nitrite as nitrogen, milligrams per liter"]) {
            record = mo_trenddata[station_id]["Dissolved nitrate plus nitrite as nitrogen, milligrams per liter"];
        }
        else if (nutrient == "Phosphorus" && mo_trenddata[station_id]["Total phosphorus, milligrams per liter"]) {
            record = mo_trenddata[station_id]["Total phosphorus, milligrams per liter"];
        }
        else if (nutrient == "Phosphorus" && mo_trenddata[station_id]["Dissolved phosphorus, milligrams per liter"]) {
            record = mo_trenddata[station_id]["Dissolved phosphorus, milligrams per liter"];
        }
    }

    if (record["trend_dir"] && record["trend_dir"] == "up" && record.significant) {
        return new Circle({
            radius: 8,
            fill: new Fill({color: '#F17720'}),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        });
    }else if (record["trend_dir"] && record["trend_dir"] == "up" && !record.significant) {
        return new Circle({
            radius: 8,
            fill: new Fill({color: '#FFA630'}),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        });
    } else if (record["trend_dir"] && record["trend_dir"] == "down" && !record.significant) {
        return new Circle({
            radius: 8,
            fill: new Fill({color: '#00A7E1'}),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        });
    }else if (record["trend_dir"] && record["trend_dir"] == "down" && record.significant) {
        return new Circle({
            radius: 8,
            fill: new Fill({color: '#0474BA'}),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        });
    }else {
        // No trend available
        return new Circle({
            radius: 3,
            fill: new Fill({color: 'white'}),
            stroke: new Stroke({
                color: 'black',
                width: 1
            }),
        });
    }
};

export const getNitrogenTrendColor = (trendProbability?: string): number => {
    if ((trendProbability !== 0 && !trendProbability) || trendProbability === '' || trendProbability === 'NA NA') {
        return 0;
    }
    if (trendProbability === 'Very Likely Decreasing') {
        return 1;
    }
    if (trendProbability === 'Likely Decreasing') {
        return 2;
    }
    if (trendProbability === 'May be Decreasing') {
        return 3;
    }
    if (trendProbability === 'Unlikely Not Changing') {
        return 4;
    }
    if (trendProbability === 'May be Increasing') {
        return 5;
    }
    return 6;
};

const noDataPattern = (() => {
    const pixelRatio = DEVICE_PIXEL_RATIO;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 8 * pixelRatio;
    canvas.height = 8 * pixelRatio;
    // white background
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);
    // line
    context.strokeStyle = '#b27eb2';
    context.lineWidth = 2;
    context.moveTo(0, 0);
    context.lineTo(6 * pixelRatio, 3 * pixelRatio);
    context.stroke();
    return context.createPattern(canvas, 'repeat');
})();

export const getFeatureStyle = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    const strokeOptions = isSelected ?
        {
            color: 'red',
            width: 4
        } :
        {
            color: '#7f7f7f',
            width: 1
        };

    const name = feature.get('Name') || feature.get('Station_ID');
    const hucid = feature.get('HUC_8');

    if (hucid) {
        let trendDir;
        let trendProb;

        trendDir = "NA"
        trendProb = "NA"

        let color;
        if (trendDir != 'NA') {
            const styleInfo = HUC8_STYLE_INFO[getNitrogenTrendColor(trendProb+' '+trendDir)];
            color = styleInfo.color ? styleInfo.color : '#000';
        } else {
            color = noDataPattern;
        }

        return (
            new Style({
                fill: new Fill({ color }),
                stroke: new Stroke(strokeOptions),
                zIndex: isSelected ? 2 : 1
            })
        );
    }
    if (annualYieldData[nutrient] && annualYieldData[nutrient][name]) {
        const nutrientLevel = name ? parseFloat(annualYieldData[nutrient][name][year]) || 0.0 : 0;

        let color;
        if (nutrientLevel >= 0) {
            const styleInfo = FEATURE_STYLE_INFO[getNutrientValueCategoryIndex(nutrientLevel)];
            color = styleInfo.color ? styleInfo.color : '#000';
        } else {
            color = noDataPattern;
        }

        return (
            new Style({
                fill: new Fill({color}),
                stroke: new Stroke(strokeOptions),
                zIndex: isSelected ? 2 : 1
            })
        );
    }
};

export const getSiteStyle = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    let trendStatement;
    let hucid = feature.get("HUC");
    let station = feature.get("MonitoringLocationIdentifier");

    if (true) {
        return new Style({
            image: getSiteIcon(station, nutrient)
        });
    } else {
        return new Style({});
    }
}

export const getTrendStyle = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    let station = feature.get("MonitoringLocationIdentifier");

    if (true) {
        return new Style({
            image: getTrendIcon(station, nutrient)
        });
    } else {
        return new Style({});
    }
}

export const getPlainStyle = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    let hucid = feature.get("HUC8_N");
    const TIER_1 = [11110103,11010003,11010004,11110203,11110207,8020402,8020205];

    if (false) {
        return (
            new Style({
                fill: new Fill({
                    color: '#fdbfbf'
                }),
                stroke: new Stroke({
                    color: '#7f7f7f',
                    width: 1
                })
            })
        );
    } else {
        return (
            new Style({
                stroke: new Stroke({
                    color: '#7f7f7f',
                    width: 1
                })
            })
        );
    }
}

export const getHUC8Style = (
    feature: FeatureType,
    resolution: ?number,
    nutrient: string,
    year: number,
    isSelected: boolean = false
) => {
    const strokeOptions = isSelected ?
        {
            color: 'red',
            width: 4
        } :
        {
            color: '#7f7f7f',
            width: 1
        };

    const hucid = feature.get('HUC_8');
    let color;
    let trendDir;
    let trendProb;

    trendDir = "NA"
    trendProb = "NA"

    if (trendDir != 'NA') {
        const styleInfo = HUC8_STYLE_INFO[getNitrogenTrendColor(trendProb+' '+trendDir)];
        color = styleInfo.color ? styleInfo.color : '#000';
    } else {
        color = noDataPattern;
    }

    return (
        new Style({
            fill: new Fill({ color }),
            stroke: new Stroke(strokeOptions),
            zIndex: isSelected ? 2 : 1
        })
    );
}

export type BoundaryType = {
    [key: string]: {
        visible: boolean;
        label: string;
        layers: Array<{
            url: string;
            style: Function;
            interactive?: boolean;
            zIndex?: number
        }>;
    };
}

export const BOUNDARIES: BoundaryType = {
    // new layer group
    huc8: {
        visible: true,
        label: 'Stations (Portal)',
        layers: [
            {
                url: huc8,
                style: getPlainStyle,
                interactive: true
            },
            {
                url: mo_sites,
                style: getSiteStyle,
                interactive: true
            },
        ]
    },
    trends: {
        visible: false,
        label: 'USGS Trends',
        layers: [
            {
                url: huc8,
                style: getPlainStyle,
                interactive: true
            },
            {
                url: trend_stations,
                style: getTrendStyle,
                interactive: true
            },
        ]
    }
};

export const VARIABLES_INFO = {
    boundary: {
        title: 'Boundary Type',
        description: (
            <div>
                <b>Drainage</b>
                <p>
                    No description available
                </p>
                <b>HUC 8</b>
                <p>
                    No description available
                </p>
            </div>
        )
    },
    nutrient: {
        title: 'Nutrient Type',
        description: (
            <div>
                <p>
                    Nitrogen and Phosphorus are the two main nutrients that cause
                    the algal blooms that lead to hypoxia in the Gulf of Mexico.
                </p>
                <p>
                    Nitrogen – the main source of nitrogen is runoff from
                    agriculture, though there are other sources as well such
                    as urban areas and industry.
                </p>
                <p>
                    Phosphorus – the main source of phosphorus is wastewater
                    treatment, though there are other sources as well such
                    as erosion.
                </p>
            </div>
        )
    },
    yield: {
        title: 'Yield',
        description: (
            <div>
                Yield is a measure of nutrients lost per unit area. This measure is useful because
                it removes the influence of watershed size in a measurement so that different size
                watersheds may be compared.
            </div>
        )
    }
};
